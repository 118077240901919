import React, { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import styles from "./product.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoModal from "./video-modal";
import { Container } from "./container";
import Image from "next/image";
import ReactGA from "react-ga";

import Slider from "react-slick";
// import ReactHtmlParser from "react-html-parser";
import DoubleHeading from "./double-heading";
import dynamic from "next/dynamic";
import { PlayIcon } from "@heroicons/react/outline";

import ModalVideo from "react-modal-video";

const getVideoCode = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

const TestimonialSlider = ({ testimonials, showHeading, productName }) => {
  const [display, setDisplay] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(true);
  const [video, setVideo] = React.useState(null);
  const [cdnVideoModal, setCdnVideoModal] = React.useState(false);

  const [readMore, setReadMore] = React.useState(-1);
  const [open, setOpen] = useState(false);

  const [testimonialEvent, setTestimonialEvent] = useState(false);

  const isValidYoutubeURL = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    if (url != undefined || url != "") {
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const openCdnVideoModal = () => {
    setCdnVideoModal(!cdnVideoModal);
  };

  // React.useEffect(() => {
  //   // For testimonial Slider
  //   setDisplay(true);
  // }, []);

  function QuoteIcon(props) {
    return (
      <svg aria-hidden="true" width={105} height={78} {...props}>
        <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
      </svg>
    );
  }

  return (
    <>
      <section
        id="testimonials"
        aria-label="What our customers are saying"
        className="bg-slate-50 py-10 sm:py-12"
      >
        <Container>
          {showHeading && (
            <div className="mx-auto max-w-2xl md:text-center">
              <DoubleHeading text="Success Stories" />
              {/* <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              Loved by businesses worldwide.
            </h2>
            <p className="mt-4 text-lg tracking-tight text-slate-700">
              Our software is so simple that people can’t help but fall in love
              with it. Simplicity is easy when you just skip tons of
              mission-critical features.
            </p> */}
            </div>
          )}
          <ul
            role="list"
            className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-16 lg:max-w-none lg:grid-cols-3"
          >
            {testimonials &&
              testimonials.length > 0 &&
              testimonials.map((testimonial, i) => (
                <li key={i}>
                  <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                    <li key={i}>
                      <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                        <QuoteIcon className="absolute top-6 left-6 fill-slate-100" />
                        <>
                          {testimonial &&
                          testimonial.video &&
                          testimonial.video !== "" ? (
                            <div
                              style={{ margin: 0 }}
                              className={`${styles.aboutVideoBox}`}
                            >
                              <div
                                className={`${styles.image} relative w-full h-48 sm:h-72 lg:h-48 rounded-lg overflow-hidden`}
                              >
                                <img
                                  src={testimonial.video_thumbnail}
                                  className="w-full h-full object-center object-cover"
                                  onClick={() => setOpen(true)}
                                />

                                <Link href="#play-video">
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      testimonial.video.includes(
                                        "www.youtube.com"
                                      )
                                        ? openModal()
                                        : openCdnVideoModal();
                                      setVideo(testimonial.video);
                                      openModal();
                                      if (productName) {
                                        if (!testimonialEvent) {
                                          ReactGA.event({
                                            category: "product page",
                                            action: "testimonial_video_watched",
                                            label: productName,
                                            // nonInteraction: false,
                                          });
                                          setTestimonialEvent(true);
                                        }
                                      }
                                    }}
                                    style={{
                                      width: "40px",
                                      height: "42px",
                                      fontSize: "17px",
                                      lineHeight: "40px",
                                    }}
                                    className={`${styles.videoBtn} popup-youtube`}
                                  >
                                    <PlayIcon />
                                  </a>
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="relative">
                              <p className="text-md">
                                {testimonial.testimonial &&
                                  testimonial.testimonial}
                              </p>
                            </div>
                          )}
                        </>
                        <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                          <div>
                            <div className="font-display text-base text-slate-900">
                              {testimonial.name}
                            </div>
                            <div className="mt-1 text-sm text-slate-500">
                              {testimonial.subline}
                            </div>
                          </div>
                          <div className="overflow-hidden rounded-full bg-slate-50">
                            <Image
                              className="h-14 w-14 object-cover"
                              src={testimonial.img}
                              alt=""
                              width={56}
                              height={56}
                            />
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
          {/* {testimonials && video && video.includes("www.youtube.com") ? (
            <ModalVideo
              channel="youtube"
              isOpen={!isOpen}
              autoplay={0}
              videoId={getVideoCode(video)}
              onClose={() => {
                setIsOpen(!isOpen);
                setVideo(null);
              }}
            />
          ) : (
            <VideoModal
              open={cdnVideoModal}
              setOpen={setCdnVideoModal}
              videoUrl={video}
            />
          )} */}
          {testimonials && video && (
            <Fragment>
              {isValidYoutubeURL(video) ? (
                <ModalVideo
                  channel="youtube"
                  isOpen={!isOpen}
                  autoplay={0}
                  videoId={getVideoCode(video)}
                  onClose={() => {
                    setIsOpen(!isOpen);
                    setVideo(null);
                  }}
                />
              ) : (
                <VideoModal
                  open={cdnVideoModal}
                  setOpen={setCdnVideoModal}
                  videoUrl={video}
                />
              )}
            </Fragment>
          )}
        </Container>
      </section>
    </>
  );
};

// const TestimonialSliderMemo = React.memo(
//   TestimonialSlider,
//   (preProps, nextProps) => {
//     if (
//       JSON.stringify(preProps.product_link) !==
//       JSON.stringify(nextProps.product_link)
//     )
//       return true;
//     return false;
//   }
// );

export default TestimonialSlider;
