import axios from "axios";

// const URL = "https://dev-orders.toppersnotes.com";
import { ORDER_SERVICE } from "../constants";
const URL = ORDER_SERVICE;

const orderAPI = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});

orderAPI.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

export default orderAPI;
